// import external modules
import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { alpha } from '@mui/material/styles';
import { Tab, Tabs, Toolbar } from '@mui/material';
import { useRouteMatch, Link } from 'react-router-dom';

// import icon svgs
import {
  AccountBalance as AccountBalanceIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  AssignmentInd as ClientIcon,
  AssignmentReturned as AssignmentReturnedIcon,
  CollectionsBookmark as CollectionsBookmarkIcon,
  Dashboard as OverviewIcon,
  FindInPage as TransactionsIcon,
  GppGood as GppGoodIcon,
  InsertChart as PlanningIcon,
  Link as LinkIcon,
  MoveToInbox as ReportsIcon,
  PeopleAlt as TeamIcon,
  Settings as SettingsIcon,
  ImageSearch as ImageSearchIcon,
  FolderOpen as FolderOpenIcon,
} from '@mui/icons-material';

import { getAppUrlPathFromId, mapAliasToPath } from '../utilities/appUtilities';
import { useConfig } from '../../common/providers/ConfigProvider';

const useStyles = makeStyles()((theme) => ({
  toolbar: {
    zIndex: 10,
    minHeight: 40,
    backgroundColor: theme.whiteLabel.SecondaryToolbar.color || 'inherit',
    boxShadow: 'none',
  },
  toolbarTabs: {
    justifyContent: 'start',
    width: '100%',
  },
  tabsRoot: {
    width: '100%',
    minHeight: 48,
  },
  tabHorizontal: {
    minHeight: 48,
    '&.Mui-selected': {
      color:
        theme.whiteLabel.SecondaryToolbar.selectedTextColor ||
        theme.palette.common.white,
    },
    color:
      theme.whiteLabel.SecondaryToolbar.textColor || 'rgba(255, 255, 255, 0.7)',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      paddingTop: 2,
      borderBottomWidth: 2,
      borderBottomColor: alpha(
        theme.whiteLabel.HeaderText.color || 'rgba(255, 255, 255, 0.7)',
      ),
      borderBottomStyle: 'solid',
    },
    textTransform: 'none',
    minWidth: 'auto',
    padding: theme.spacing(0, 2),
    fontSize: '0.85rem',
  },
  tabHorizontalEnd: {
    '&.Mui-selected': {
      color:
        theme.whiteLabel.SecondaryToolbar.selectedTextColor ||
        theme.palette.common.white,
    },
    color:
      theme.whiteLabel.SecondaryToolbar.textColor || 'rgba(255, 255, 255, 0.7)',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      borderBottomWidth: 2,
      borderBottomColor: 'rgba(255, 255, 255, 0.6)',
      borderBottomStyle: 'solid',
      '&.MuiTab-root': {
        paddingTop: 2,
      },
    },
    textTransform: 'none',
    minWidth: 'auto',
    marginLeft: 'auto',
  },
  indicator: {
    backgroundColor:
      theme.whiteLabel.Indicator.color || theme.palette.common.white,
    height: theme.whiteLabel.Indicator.height || 2,
  },
  tabIcon: {
    fontSize: '1.15rem',
  },
}));

const checkActive = ({ match, path, matchRoutes, isExact }) => {
  let matchPath = path;

  if (matchRoutes && matchRoutes.includes(match.path)) {
    matchPath = match.path;
  }
  if (isExact) {
    return match.path === matchPath && match.isExact ? 'active' : 'inactive';
  }
  return match.path.includes(matchPath) ? 'active' : 'inactive';
};

export const appTabs = ({
  audit,
  accountConfig,
  tabIconClass,
  mapPathAlias,
  permissions,
  monitoringActive,
  accountId,
}) => ({
  accounting: [
    {
      label: 'Dashboard',
      icon: <AccountBalanceIcon className={tabIconClass} />,
      name: 'Dashboard',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('accounting')}`,
      isExact: true,
      link: `/${getAppUrlPathFromId('accounting')}`,
      key: 1,
      hidden: !accountConfig.enableAccountingDashboard,
    },
    {
      label: 'Crypto Sources',
      icon: <AccountBalanceWalletIcon className={tabIconClass} />,
      name: 'Crypto Sources',
      permission: 'sourceView',
      matchRoutes: [
        `${mapPathAlias('/:app(accounting|tax)')}/sources`,
        `${mapPathAlias('/:app(accounting|tax)')}/sources/add`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges/:exchangeId?/:subPage?`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges/:exchangeId?/:subPage?/:importId?`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets/:walletId?/:subPage?`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets/:walletId?/:subPage?/:importId?`,
      ],
      isExact: false,
      link: `/${getAppUrlPathFromId('accounting')}/sources`,
      hidden: !accountConfig.enableSources,
      key: 2,
    },
    {
      label: 'Reports',
      icon: <AssignmentReturnedIcon className={tabIconClass} />,
      name: 'Reports',
      permission: 'reportsView',
      matchRoute: `${mapPathAlias('/:app(accounting|tax)')}/reports`,
      isExact: false,
      link: `/${getAppUrlPathFromId('accounting')}/reports`,
      hidden: !accountConfig.enableReports,
      key: 3,
    },
    {
      label: 'Integrations',
      icon: <LinkIcon className={tabIconClass} />,
      name: 'Integrations',
      permission: 'integrationsView',
      matchRoute: `/${getAppUrlPathFromId('accounting')}/integrations`,
      isExact: false,
      link: `/${getAppUrlPathFromId('accounting')}/integrations`,
      hidden: !accountConfig.enableIntegrations,
      key: 4,
    },
    {
      label: 'Contacts',
      icon: <CollectionsBookmarkIcon className={tabIconClass} />,
      name: 'Contacts',
      permission: 'contactView',
      matchRoute: `/${getAppUrlPathFromId('accounting')}/contacts`,
      isExact: true,
      link: `/${getAppUrlPathFromId('accounting')}/contacts`,
      hidden: !accountConfig.enableContacts,
      key: 5,
    },
    {
      label: 'Transactions',
      icon: <TransactionsIcon className={tabIconClass} />,
      name: 'Transactions',
      permission: 'txSearchView',
      matchRoute: `/${getAppUrlPathFromId('accounting')}/transactions`,
      isExact: false,
      link: `/${getAppUrlPathFromId('accounting')}/transactions`,
      hidden: !accountConfig.enableAccountingTransactions || audit,
      key: 6,
    },
    {
      label: 'NFT Suite',
      icon: <ImageSearchIcon className={tabIconClass} />,
      name: 'NFT Suite',
      permission: false,
      matchRoute: `${mapPathAlias('/:app(accounting|tax)')}/nfts`,
      isExact: true,
      link: `/${getAppUrlPathFromId('accounting')}/nfts`,
      hidden: !accountConfig.enableNFTSuite,
      key: 7,
    },
    {
      onboardTarget: 'monitoring-page',
      label: 'Monitoring',
      icon: <GppGoodIcon className={tabIconClass} />,
      name: 'Monitoring',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('accounting')}/monitoring`,
      isExact: true,
      link: `/${getAppUrlPathFromId('accounting')}/monitoring`,
      hidden: !accountConfig.enableMonitoring || !monitoringActive, // This tab doesn't hide
      key: 8,
    },
    {
      onboardTarget: 'settings-page',
      label: 'Administration',
      icon: <SettingsIcon className={tabIconClass} />,
      name: 'Administration',
      permission: 'accountSettingsView',
      matchRoute: `/${getAppUrlPathFromId('accounting')}/administration`,
      isExact: false,
      link: `/${getAppUrlPathFromId('accounting')}/administration`,
      hidden: !accountConfig.enableAccountingSettings,
      key: 9,
    },
  ],
  tax: [
    {
      label: `${accountConfig.hideTaxLabels ? 'Portfolio' : 'Tax Overview'}`,
      icon: <OverviewIcon className={tabIconClass} />,
      name: `${accountConfig.hideTaxLabels ? 'Portfolio' : 'Tax Overview'}`,
      permission: false,
      matchRoutes: [
        `/${getAppUrlPathFromId('tir-admin')}/client/:accountId`,
        `/${getAppUrlPathFromId('tax')}`,
      ],
      isExact: true,
      link: permissions.isTirAdminViewingClient
        ? `/${getAppUrlPathFromId('tir-admin')}/client/${accountId}`
        : `/${getAppUrlPathFromId('tax')}`,
      key: 20,
    },
    {
      label: 'Crypto Sources',
      icon: <AccountBalanceWalletIcon className={tabIconClass} />,
      name: 'Crypto Sources',
      permission: 'sourceView',
      matchRoutes: [
        `${mapPathAlias('/:app(accounting|tax)')}/sources`,
        `${mapPathAlias('/:app(accounting|tax)')}/sources/add`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges/:exchangeId?/:subPage?`,
        `${mapPathAlias('/:app(accounting|tax)')}/exchanges/:exchangeId?/:subPage?/:importId?`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets/:walletId?/:subPage?`,
        `${mapPathAlias('/:app(accounting|tax)')}/wallets/:walletId?/:subPage?/:importId?`,
      ],
      isExact: false,
      link: `/${getAppUrlPathFromId('tax')}/sources`,
      hidden:
        !accountConfig.enableSources || permissions?.isTirAdminViewingClient,
      key: 21,
    },
    {
      label: 'Transactions',
      icon: <TransactionsIcon className={tabIconClass} />,
      name: 'Transactions',
      permission: false,
      matchRoutes: [
        `/${getAppUrlPathFromId('tir-admin')}/client/:accountId/transactions`,
        `/${getAppUrlPathFromId('tax')}/transactions`,
      ],
      isExact: true,
      link: permissions.isTirAdminViewingClient
        ? `/${getAppUrlPathFromId(
            'tir-admin',
          )}/client/${accountId}/transactions`
        : `/${getAppUrlPathFromId('tax')}/transactions`,
      hidden: !accountConfig.enableTaxTransactions,
      key: 22,
    },
    {
      label: 'Reports',
      icon: <ReportsIcon className={tabIconClass} />,
      name: 'Reports',
      permission: 'reportsView',
      matchRoute: `${mapPathAlias('/:app(accounting|tax)')}/reports`,
      isExact: false,
      link: `/${getAppUrlPathFromId('tax')}/reports`,
      hidden:
        !accountConfig.enableReports || permissions?.isTirAdminViewingClient,
      key: 23,
    },
    {
      label: 'Planning',
      icon: <PlanningIcon className={tabIconClass} />,
      name: 'Planning',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('tax')}/tax-planning`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tax')}/tax-planning`,
      hidden:
        !accountConfig.enablePlanning ||
        (permissions && permissions.isTirAdminViewingClient),
      key: 24,
    },
    {
      label: 'NFT Suite',
      icon: <ImageSearchIcon className={tabIconClass} />,
      name: 'NFT Suite',
      permission: false,
      matchRoute: `${mapPathAlias('/:app(accounting|tax)')}/nfts`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tax')}/nfts`,
      hidden:
        !accountConfig.enableNFTSuite ||
        (permissions && permissions.isTirAdminViewingClient),
      key: 25,
    },
    {
      label: 'Settings',
      icon: <SettingsIcon className={tabIconClass} />,
      name: 'Settings',
      permission: 'accountSettingsView',
      matchRoutes: [
        `/${getAppUrlPathFromId(
          'tir-admin',
        )}/client/:accountId/settings/:subPage(general|logs)`,
        `/${getAppUrlPathFromId('tax')}/settings/:subPage(general|logs)`,
      ],
      isExact: true,
      link: permissions.isTirAdminViewingClient
        ? `/${getAppUrlPathFromId(
            'tir-admin',
          )}/client/${accountId}/settings/general`
        : `/${getAppUrlPathFromId('tax')}/settings/general`,
      key: 26,
    },
  ],
  'tax-preparer': [
    {
      label: 'Clients',
      icon: <ClientIcon className={tabIconClass} />,
      name: 'Clients',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('tax-preparer')}`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tax-preparer')}`,
      key: 31,
    },
    {
      label: 'Team',
      icon: <TeamIcon className={tabIconClass} />,
      name: 'Team',
      permission: 'teamView',
      matchRoute: `/${getAppUrlPathFromId('tax-preparer')}/team`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tax-preparer')}/team`,
      key: 32,
    },
    {
      label: 'Reports',
      icon: <ReportsIcon className={tabIconClass} />,
      name: 'Reports',
      permission: 'reportsView',
      matchRoute: `/${getAppUrlPathFromId('tax-preparer')}/reports`,
      isExact: false,
      link: `/${getAppUrlPathFromId('tax-preparer')}/reports`,
      hidden: !accountConfig.enableReports,
      key: 33,
    },
    {
      label: 'Administration',
      icon: <SettingsIcon className={tabIconClass} />,
      name: 'Administration',
      permission: 'accountSettingsView',
      matchRoute: `/${getAppUrlPathFromId(
        'tax-preparer',
      )}/administration/:subPage(general|logs)`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tax-preparer')}/administration/general`,
      key: 34,
    },
  ],
  'tir-admin': [
    {
      label: 'Clients',
      icon: <ClientIcon className={tabIconClass} />,
      name: 'Clients',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('tir-admin')}`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tir-admin')}`,
      key: 35,
    },
    {
      label: 'Team',
      icon: <TeamIcon className={tabIconClass} />,
      name: 'Team',
      permission: 'teamView',
      matchRoute: `/${getAppUrlPathFromId('tir-admin')}/team`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tir-admin')}/team`,
      key: 36,
    },
    {
      label: 'Files',
      icon: <FolderOpenIcon className={tabIconClass} />,
      name: 'Files',
      permission: false,
      matchRoute: `/${getAppUrlPathFromId('tir-admin')}/files`,
      isExact: false,
      link: `/${getAppUrlPathFromId('tir-admin')}/files`,
      key: 37,
    },
    {
      label: 'Administration',
      icon: <SettingsIcon className={tabIconClass} />,
      name: 'Administration',
      permission: 'accountSettingsView',
      matchRoute: `/${getAppUrlPathFromId(
        'tir-admin',
      )}/administration/:subPage(general|logs)`,
      isExact: true,
      link: `/${getAppUrlPathFromId('tir-admin')}/administration/general`,
      key: 38,
    },
  ],
});

const SecondaryToolbar = ({ permissions, appType, currentAccount }) => {
  const match = useRouteMatch();
  const { accountConfig, appConfig } = useConfig();
  const {
    taxAppUrlAlias,
    taxProAppUrlAlias,
    accountingAppUrlAlias,
    tirAdminAppUrlAlias,
  } = appConfig;
  const {
    id: accountId,
    audit,
    chainalysisIntegrationActive,
    chainalysisIntegrationEnabled,
  } = currentAccount;
  const { classes, cx } = useStyles();

  const mapPathAlias = useCallback(
    (path) =>
      mapAliasToPath(path, {
        taxAppUrlAlias,
        taxProAppUrlAlias,
        accountingAppUrlAlias,
        tirAdminAppUrlAlias,
      }),
    [
      accountingAppUrlAlias,
      taxAppUrlAlias,
      taxProAppUrlAlias,
      tirAdminAppUrlAlias,
    ],
  );

  // generate array from appTabs and filter out any tabs with hidden property based on
  // any conditions applied, can pass args to appTabs
  const visibleTabs = useMemo(() => {
    const vis = appType
      ? appTabs({
          audit,
          monitoringActive:
            chainalysisIntegrationEnabled && chainalysisIntegrationActive,
          tabIconClass: classes.tabIcon,
          accountConfig,
          mapPathAlias,
          permissions,
          accountId,
        })[appType].filter((tab) => !tab.hidden)
      : [];

    return vis.map((tab) => (
      <Tab
        component={Link}
        key={tab.key}
        className={`${
          tab.permission !== 'accountSettingsView'
            ? classes.tabHorizontal
            : cx(classes.tabHorizontal, classes.tabHorizontalEnd)
        }`}
        icon={<span>{tab.icon}</span>}
        iconPosition="start"
        label={tab.label}
        value={checkActive({
          match,
          matchRoutes: tab.matchRoutes,
          path: tab.matchRoute,
          isExact: tab.isExact,
        })}
        to={tab.link}
        disabled={tab.permission && !permissions[tab.permission]}
      />
    ));
  }, [
    appType,
    audit,
    chainalysisIntegrationEnabled,
    chainalysisIntegrationActive,
    classes.tabIcon,
    classes.tabHorizontal,
    classes.tabHorizontalEnd,
    accountConfig,
    mapPathAlias,
    permissions,
    accountId,
    cx,
    match,
  ]);

  return (
    <Toolbar className={classes.toolbar} variant="dense" disableGutters>
      <Tabs
        value="active"
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.toolbarTabs,
        }}
        TabIndicatorProps={{
          className: classes.indicator,
        }}
      >
        {visibleTabs.map((tab) => tab)}
      </Tabs>
    </Toolbar>
  );
};

export default SecondaryToolbar;

SecondaryToolbar.propTypes = {
  permissions: PropTypes.shape().isRequired,
  appType: PropTypes.string.isRequired,
  currentAccount: PropTypes.shape().isRequired,
};
