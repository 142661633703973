import { lazy } from 'react';

/* BASE MODALS */
const ErrorModal = lazy(() => import('./error-modal/ErrorModal'));
const TokenExpiredModal = lazy(() =>
  import('../authentication/token-expired-modal/TokenExpiredModal'),
);
const AddAddressModal = lazy(() =>
  import('../../common/wallets/modals/add-address-modal/AddAddressModal'),
);
const AddressUploadModal = lazy(() =>
  import('../../common/wallets/modals/address-upload-modal/AddressUploadModal'),
);
const ConnectWalletModal = lazy(() =>
  import(
    '../../common/wallets/modals/wallet-connection-modal/ConnectWalletModal'
  ),
);
const DeleteWalletModal = lazy(() =>
  import('../../common/wallets/modals/delete-wallet-modal/DeleteWalletModal'),
);
const DeleteExchangeModal = lazy(() =>
  import(
    '../../common/exchanges/modals/delete-exchange-modal/DeleteExchangeModal'
  ),
);
const DeleteAddressModal = lazy(() =>
  import('../../common/wallets/modals/delete-address-modal/DeleteAddressModal'),
);
const CreateAccountingReportModal = lazy(() =>
  import(
    '../../accounting/reports/create-report-modal/CreateAccountingReportModalContainer'
  ),
);
const DeleteReportModal = lazy(() =>
  import('../../common/reports/modals/delete-report-modal/DeleteReportModal'),
);
const CancelReportModal = lazy(() =>
  import('../../common/reports/modals/cancel-report-modal/CancelReportModal'),
);
const ReportDetailsModal = lazy(() =>
  import(
    '../../accounting/reports/manage-reports/report-details-modal/ReportDetailsModal'
  ),
);
const UpdateExchangeAuth = lazy(() =>
  import(
    '../../common/exchanges/modals/update-credentials-modal/UpdateCredentialsModalContainer'
  ),
);
const BrowserUnsupportedModal = lazy(() =>
  import('../browser-detection/BrowserUnsupportedModal'),
);
const MobileUnsupportedModal = lazy(() =>
  import('../browser-detection/MobileUnsupportedModal'),
);
const ViewHDKeyModal = lazy(() =>
  import('../../common/wallets/modals/view-hd-key-modal/ViewHDKeyModal'),
);
const NftModal = lazy(() =>
  import('../../common/wallets/modals/nft-modal/NftModal'),
);
const UpdateWalletCredentialsModal = lazy(() =>
  import(
    '../../common/wallets/modals/update-wallet-credentials-modal/UpdateWalletCredentialsModal'
  ),
);
const DeleteCustodianModal = lazy(() =>
  import(
    '../../common/wallets/modals/delete-custodian-modal/DeleteCustodianModal'
  ),
);
const ChangePassword = lazy(() =>
  import('../../common/user-profile/change-password-modal/ChangePasswordModal'),
);
const BadConnectionModal = lazy(() =>
  import('./bad-connection-modal/BadConnectionModal'),
);
const TurnOnMfaModal = lazy(() =>
  import(
    '../../common/user-profile/profile-page-sections/security-section/mfa/TurnOnMfaModal'
  ),
);
const TurnOffMfaModal = lazy(() =>
  import(
    '../../common/user-profile/profile-page-sections/security-section/mfa/TurnOffMfaModal'
  ),
);
const OrderImportModal = lazy(() =>
  import('../../common/importing/order/OrderImportModal'),
);
const TransactionImportModal = lazy(() =>
  import('../../common/importing/transaction/TxImportModal'),
);
const UpdateAccountTimezoneModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/account-section/update-account-timezone-modal/UpdateAccountTimezoneModal'
    ),
);
const UpdateClientAccountTimezoneModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/update-client-account-timezone-modal/UpdateClientAccountTimezoneModal'
    ),
);
const ClearExchangeBalanceModal = lazy(() =>
  import(
    '../../common/exchanges/modals/clear-exchange-balance-modal/ClearExchangeBalanceModal'
  ),
);
const AddAssetBalanceModal = lazy(() =>
  import(
    '../../common/exchanges/modals/add-asset-balance-modal/AddAssetBalanceModal'
  ),
);
const UpdateExchangeBalanceModal = lazy(() =>
  import(
    '../../common/exchanges/modals/update-exchange-balance-modal/UpdateExchangeBalanceModal'
  ),
);
const AddSourceModal = lazy(() =>
  import('../../common/sources/add-source-modal/AddSourceModalContainer'),
);
const CreateBulkMemosModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/create-bulk-memos-modal/CreateBulkMemosModal'
  ),
);
const DeleteBulkMemosModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/delete-bulk-memos-modal/DeleteBulkMemosModal'
  ),
);
const ResetBulkCategoriesModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/reset-bulk-categories-modal/ResetBulkCategoriesModal'
  ),
);
const FilterSettingsModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/filter-settings-modal/FilterSettingsModal'
  ),
);
const CreateMemo = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/create-memo-modal/CreateMemoModal'
  ),
);
const DeleteMemo = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/delete-memo-modal/DeleteMemoModal'
  ),
);
const NftDetailsModal = lazy(() =>
  import('../../common/nfts/modals/NftDetailsModal'),
);
const ConfirmActionModal = lazy(() =>
  import('./confirm-action-modal/ConfirmActionModal'),
);

/* ACCOUNTING SPECIFIC */
const AddContactModal = lazy(
  () => import('../../accounting/contacts/add-contact-modal/AddContactModal'),
);
const BulkContactModal = lazy(
  () =>
    import(
      '../../accounting/contacts/bulk-contact-upload-modal/BulkContactUploadModal'
    ),
);
const AddUserModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/add-user-modal/AddUserModalContainer'
    ),
);
const DeleteContactModal = lazy(
  () =>
    import(
      '../../accounting/contacts/manage-contacts/delete-contact-modal/DeleteContactModal'
    ),
);
const DeleteUserModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/active-users/delete-user-modal/DeleteUserModal'
    ),
);
const UpdateContactModal = lazy(
  () =>
    import(
      '../../accounting/contacts/manage-contacts/update-contact-modal/UpdateContactModal'
    ),
);
const ChangePermissionModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/active-users/change-permission-modal/ChangePermissionModal'
    ),
);
const ChangeOwnerModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/active-users/change-owner-modal/ChangeOwnerModal'
    ),
);
const ResendInviteModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/requested-users/reset-invite-modal/ResetInviteModal'
    ),
);
const CancelInviteModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/users-tab/requested-users/cancel-invite-modal/CancelInviteModal'
    ),
);
const DeleteAccountModal = lazy(
  () =>
    import('../../accounting/accounts/delete-account-modal/DeleteAccountModal'),
);
const CreateAddressReportModal = lazy(
  () => import('../../accounting/wallets/CreateAddressReportModal'),
);
const OverLimitModal = lazy(
  () =>
    import('../../accounting/billing/over-limit-warning-modal/OverLimitModal'),
);
const DeleteConnectionModal = lazy(
  () =>
    import(
      '../../accounting/integrations/delete-connection-modal/DeleteConnectionModal'
    ),
);
const IntegrationAuthErrorModal = lazy(
  () =>
    import(
      '../../accounting/integrations/integration-auth-error-modal/IntegrationAuthErrorModal'
    ),
);
const SyncNowModal = lazy(
  () =>
    import(
      '../../accounting/integrations/manage-integration-page/sync-now-modal/SyncNowModal'
    ),
);
const IntegrationSetupModal = lazy(
  () =>
    import(
      '../../accounting/integrations/manage-integration-page/setup-integration-modal/SetupIntegrationModal'
    ),
);
const MapAssetsModal = lazy(
  () =>
    import(
      '../../accounting/integrations/manage-integration-page/asset-currency-mapping-modal/MapAssetsModalContainer'
    ),
);
const MapCurrenciesModal = lazy(
  () =>
    import(
      '../../accounting/integrations/manage-integration-page/asset-currency-mapping-modal/MapCurrenciesModalContainer'
    ),
);
const ChangeCurrencyModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/settings-tab/settings-section/change-currency-modal/ChangeCurrencyModal'
    ),
);
const TxSettingsModal = lazy(
  () =>
    import(
      '../../accounting/transaction-table/tx-table-settings/TxSettingsModalContainer'
    ),
);
const SaveViewModal = lazy(
  () =>
    import(
      '../../accounting/transaction-table/tx-table-views/save-view-modal/SaveViewModalContainer'
    ),
);
const DeleteViewModal = lazy(
  () =>
    import(
      '../../accounting/transaction-table/tx-table-views/delete-view-modal/DeleteViewModal'
    ),
);
const TxReportModal = lazy(
  () =>
    import(
      '../../accounting/transaction-table/tx-report/TxReportModalContainer'
    ),
);
const CreateTxMemoModal = lazy(
  () => import('../../accounting/transaction-table/tx-modals/CreateMemoModal'),
);
const UpdateTxMemoModal = lazy(
  () => import('../../accounting/transaction-table/tx-modals/UpdateMemoModal'),
);
const CreateTxBulkMemosModal = lazy(
  () =>
    import('../../accounting/transaction-table/tx-modals/CreateBulkMemosModal'),
);
const DeleteTxMemoModal = lazy(
  () =>
    import('../../accounting/transaction-table/tx-modals/DeleteBulkMemosModal'),
);
const CreateAccountModal = lazy(
  () =>
    import('../../accounting/billing/create-account-modal/CreateAccountModal'),
);
const ConvertFromTrialModal = lazy(
  () =>
    import(
      '../../accounting/billing/convert-from-trial-modal/ConvertFromTrialModal'
    ),
);
const ChangeCurrentPlanModal = lazy(
  () =>
    import(
      '../../accounting/billing/change-current-plan-modal/ChangeCurrentPlanModal'
    ),
);
const EnableIntegrationsModal = lazy(
  () =>
    import(
      '../../accounting/billing/enable-integrations-modal/EnableIntegrationsModal'
    ),
);
const CancelSubscriptionModal = lazy(
  () =>
    import(
      '../../accounting/billing/cancel-subscription-modal/CancelSubscriptionModal'
    ),
);
const MobileTxFilterModal = lazy(
  () =>
    import(
      '../../accounting/transaction-table/tx-table-mobile-filters-modal/MobileTxFilterModal'
    ),
);
const IntegrationModal = lazy(
  () =>
    import(
      '../../accounting/account-settings/settings-tab/integration-section/integration-chainalysis-modal/IntegrationChainalysisModalContainer'
    ),
);
/* TAX PAYER SPECIFIC */
const TaxConsentModal = lazy(() =>
  import('../../tax/payer-components/tax-consent/ConsentModal'),
);
const TaxReviewModal = lazy(() =>
  import('../../tax/tax-overview/modals/TaxReviewModal'),
);
const EditOrderEntryModal = lazy(() =>
  import(
    '../../common/manual-entry/order/modals/edit-order-entry-modal/EditOrderEntryModal'
  ),
);
const EditTxEntryModal = lazy(() =>
  import('../../common/manual-entry/transaction/modals/edit-tx-entry-modal/EditTxEntryModal'),
);
const CreateOrderEntryModal = lazy(() =>
  import(
    '../../common/manual-entry/order/modals/create-order-entry-modal/CreateOrderEntryModal'
  ),
);
const CreateTxEntryModal = lazy(() =>
  import(
    '../../common/manual-entry/transaction/modals/create-tx-entry-modal/CreateTxEntryModal'
  ),
);
const DeleteManualOrderEntryModal = lazy(() =>
  import(
    '../../common/manual-entry/order/modals/delete-order-entry-modal/DeleteOrderEntryModal'
  ),
);
const DeleteManualTxEntryModal = lazy(() =>
  import(
    '../../common/manual-entry/transaction/modals/delete-tx-entry-modal/DeleteTxEntryModal'
  ),
);
const IgnoreTaxTxModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/ignore-tax-tx-modal/IgnoreTaxTxModal'
  ),
);
const UpdateTransferMatchModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/update-transfer-match-modal/UpdateTransferMatchModal'
  ),
);
const EditCostBasisModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/edit-cost-basis-modal/EditCostBasisModalContainer'
  ),
);
const RevertCostBasisModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/revert-cost-basis-modal/RevertCostBasisModal'
  ),
);
const UpdateClientTaxMethodModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/update-client-tax-method-modal/UpdateClientTaxMethodModal'
    ),
);
const UpdatePayerTaxMethodModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/tax-section/update-payer-tax-method-modal/UpdatePayerTaxMethodModal'
    ),
);
const UpdateClientCostBasisModeModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/update-client-cost-basis-mode-modal/UpdateClientCostBasisModeModal'
    ),
);
const UpdatePayerCostBasisModeModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/tax-section/update-payer-cost-basis-mode-modal/UpdatePayerCostBasisModeModal'
    ),
);
const UpdateClientTaxYearModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/update-client-tax-year-modal/UpdateClientTaxYearModal'
    ),
);
const UpdatePayerTaxYearModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/tax-section/update-payer-tax-year-modal/UpdatePayerTaxYearModal'
    ),
);
const TriggerAccountUpdateModal = lazy(
  () => import('../../common/account-updates/TriggerAccountUpdateModal'),
);
const AddTaxProModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/manage-preparers-section/add-tax-pro-modal/AddTaxProModal'
    ),
);
const RemoveTaxProModal = lazy(() =>
  import(
    '../../tax/tax-settings/settings-tab/manage-preparers-section/remove-tax-pro-modal/RemoveTaxProModal'
  ),
);
const ClientReportStatusModal = lazy(() =>
  import('../../tax/tax-overview/tax-steps-summary/ClientReportStatusModal'),
);
const CreateManualSourceModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-modals/create-source-modal/CreateManualSourceModal'
  ),
);
const MobileTaxTxFilterModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-modals/mobile-tax-tx-filter-modal/MobileTaxTxFilterModal'
  ),
);
const TaxCategorizationModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-modals/tax-categorization-modal/CategorizationEngineModal'
  ),
);
const ResetTaxTxCategoryModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/reset-tax-tx-category-modal/ResetTaxTxCategoryModal'
  ),
);
const SelectOrganizationModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/select-organization-modal/SelectOrganizationModal'
  ),
);
const CostAssignmentDetailsModal = lazy(() =>
  import(
    '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/cost-assignment-details-modal/CostAssignmentDetailsModal'
  ),
);
const UpdateSpecIdModal = lazy(
  () =>
    import(
      '../../tax/tax-transactions/tax-tx-table/tax-tx-table-modals/update-spec-id-modal/UpdateSpecIdModal'
    ),
);
const UpdateSpecIdSettingsModal = lazy(
  () =>
    import(
      '../../tax/tax-settings/settings-tab/tax-section/update-spec-id-settings-modal/UpdateSpecIdSettingsModal'
    ),
);
const SpecIdWarningModal = lazy(
  () =>
    import(
      '../../tax/tax-transactions/tax-tx-modals/spec-id-warning-modal/SpecIdWarningModal'
    ),
);

/* TAX PREPARER SPECIFIC */
const AddPreparerModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-team/add-preparer-modal/AddPreparerModalContainer'
    ),
);
const DeletePreparerModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-team/manage-team/delete-preparer-modal/DeletePreparerModal'
    ),
);
const CancelPreparerModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-team/manage-team/cancel-invite-modal/CancelInviteModal'
    ),
);
const ResendPreparerInviteModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-team/manage-team/resend-invite-modal/ResendInviteModal'
    ),
);
const AddClientModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/add-client-modal/AddClientModal'
    ),
);
const TaxReportDetailsModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-reports/manage-preparer-reports/report-details-modal/TaxReportDetailsModal'
    ),
);
const DeleteClientModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/delete-client-modal/DeleteClientModal'
    ),
);
const UpdateClientDetailsModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/update-client-details-modal/UpdateClientDetailsModal'
    ),
);
const CreatePayerTaxReportModal = lazy(
  () => import('../../tax/tax-reports/CreatePayerTaxReportModalContainer'),
);
const CreatePreparerTaxReportModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-reports/create-report-modal/CreatePreparerTaxReportModalContainer'
    ),
);
const ResendPreparerCodeInviteModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/resend-code-invite-modal/ResendCodeInviteModal'
    ),
);
const ClientAccessChangedModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/client-access-changed-modal/ClientAccessChangedModal'
    ),
);
const PreparerModal = lazy(() => import('../header/PreparerModal'));
const BillingChargesModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/billing-charges-modal/BillingChargesModal'
    ),
);
const FeeAssignmentModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/fee-assignment-modal/FeeAssignmentModal'
    ),
);
const TriggerClientAccountUpdateModal = lazy(
  () =>
    import(
      '../../tax-preparer/preparer-overview/active-clients-component/trigger-client-account-update-modal/TriggerClientAccountUpdateModal'
    ),
);
const MaterialityThresholdModal = lazy(
  () => import('../../common/audit/modals/MaterialityThresholdModal'),
);

/* TIR ADMIN SPECIFIC */
const GenerateReportingFileModal = lazy(
  () =>
    import('../../tir-admin/tir-files/tir-modals/GenerateReportingFileModal'),
);
const SubmitCorrectionFileModal = lazy(
  () =>
    import('../../tir-admin/tir-files/tir-modals/SubmitCorrectionFileModal'),
);
const AddTirAdminModal = lazy(
  () =>
    import(
      '../../tir-admin/tir-team/add-tir-admin-modal/AddTirAdminModalContainer'
    ),
);

const MODALS = {
  // Add Stuff
  ADD_ADDRESS: AddAddressModal,
  CONNECT_WALLET: ConnectWalletModal,
  CREATE_ACCOUNTING_REPORT: CreateAccountingReportModal,
  CREATE_ADDRESS_REPORT: CreateAddressReportModal,
  ADD_CONTACT: AddContactModal,
  BULK_ADD_CONTACT: BulkContactModal,
  ADD_USER: AddUserModal,
  SAVE_TX_VIEW: SaveViewModal,
  CREATE_TX_MEMO: CreateTxMemoModal,
  CREATE_TX_REPORT: TxReportModal,
  CREATE_ORDER_ENTRY: CreateOrderEntryModal,
  CREATE_TX_ENTRY: CreateTxEntryModal,
  ADD_SOURCE: AddSourceModal,
  CREATE_BULK_MEMOS: CreateBulkMemosModal,
  CREATE_MEMO: CreateMemo,
  // Edit Stuff
  UPDATE_EXCHANGE_AUTH: UpdateExchangeAuth,
  UPDATE_WALLET_CREDENTIALS: UpdateWalletCredentialsModal,
  EDIT_CONTACT: UpdateContactModal,
  CHANGE_CURRENCY: ChangeCurrencyModal,
  UPDATE_ACCOUNT_TIMEZONE: UpdateAccountTimezoneModal,
  UPDATE_TX_MEMO: UpdateTxMemoModal,
  CREATE_TX_BULK_MEMOS: CreateTxBulkMemosModal,
  UPDATE_EXCHANGE_BALANCE: UpdateExchangeBalanceModal,
  ADD_ASSET_BALANCE: AddAssetBalanceModal,
  EDIT_ORDER_ENTRY: EditOrderEntryModal,
  EDIT_TX_ENTRY: EditTxEntryModal,
  EDIT_MATERIALITY_THRESHOLD: MaterialityThresholdModal,
  // Delete Stuff
  DELETE_WALLET: DeleteWalletModal,
  DELETE_CUSTODIAN: DeleteCustodianModal,
  DELETE_EXCHANGE: DeleteExchangeModal,
  DELETE_ADDRESS: DeleteAddressModal,
  DELETE_REPORT: DeleteReportModal,
  CANCEL_REPORT: CancelReportModal,
  DELETE_ACCOUNT: DeleteAccountModal,
  DELETE_CONTACT: DeleteContactModal,
  DELETE_USER: DeleteUserModal,
  DELETE_CONNECTION: DeleteConnectionModal,
  SYNC_NOW: SyncNowModal,
  DELETE_TX_VIEW: DeleteViewModal,
  DELETE_TX_MEMO: DeleteTxMemoModal,
  CLEAR_EXCHANGE_BALANCE: ClearExchangeBalanceModal,
  DELETE_ORDER_ENTRY: DeleteManualOrderEntryModal,
  DELETE_TX_ENTRY: DeleteManualTxEntryModal,
  DELETE_BULK_MEMOS: DeleteBulkMemosModal,
  RESET_BULK_CATEGORIES: ResetBulkCategoriesModal,
  DELETE_MEMO: DeleteMemo,
  // Other Actions
  ADDRESS_UPLOAD: AddressUploadModal,
  REPORT_DETAILS: ReportDetailsModal,
  VIEW_HD_KEY: ViewHDKeyModal,
  NFT_MODAL: NftModal,
  RESET_PW: ChangePassword,
  TURN_MFA_ON: TurnOnMfaModal,
  TURN_MFA_OFF: TurnOffMfaModal,
  CHANGE_PERMISSION: ChangePermissionModal,
  CHANGE_OWNER: ChangeOwnerModal,
  RESEND_INVITE: ResendInviteModal,
  CANCEL_INVITE: CancelInviteModal,
  SETUP_INTEGRATION: IntegrationSetupModal,
  MAP_ASSETS: MapAssetsModal,
  MAP_CURRENCIES: MapCurrenciesModal,
  OVER_TX_LIMIT: OverLimitModal,
  TX_SETTINGS: TxSettingsModal,
  ORDER_IMPORT: OrderImportModal,
  TRANSACTION_IMPORT: TransactionImportModal,
  TRIGGER_ACCOUNT_UPDATE: TriggerAccountUpdateModal,
  TAX_FILTER_SETTINGS: FilterSettingsModal,
  NFT_DETAILS: NftDetailsModal,
  CONFIRM_ACTION: ConfirmActionModal,
  // Error Interception
  GENERIC_ERROR: ErrorModal,
  TOKEN_EXPIRED: TokenExpiredModal,
  BROWSER_UNSUPPORTED: BrowserUnsupportedModal,
  MOBILE_UNSUPPORTED: MobileUnsupportedModal,
  BAD_CONNECTION: BadConnectionModal,
  INTEGRATION_AUTH_ERROR: IntegrationAuthErrorModal,
  // Accounting Specific
  CREATE_ACCOUNTING_ACCOUNT: CreateAccountModal,
  CONVERT_FROM_ACCOUNTING_TRIAL: ConvertFromTrialModal,
  CHANGE_ACCOUNTING_PLAN: ChangeCurrentPlanModal,
  ENABLE_INTEGRATIONS: EnableIntegrationsModal,
  CANCEL_SUBSCRIPTION: CancelSubscriptionModal,
  INTEGRATE_CHAINALYSIS: IntegrationModal,
  // Tax Preparer Specific
  ADD_PREPARER: AddPreparerModal,
  ADD_CLIENT: AddClientModal,
  RESEND_PREPARER_INVITE: ResendPreparerInviteModal,
  CANCEL_PREPARER_INVITE: CancelPreparerModal,
  DELETE_PREPARER: DeletePreparerModal,
  CREATE_TAX_REPORT: CreatePayerTaxReportModal,
  CREATE_TAX_PREPARER_REPORT: CreatePreparerTaxReportModal,
  TAX_REPORT_DETAILS: TaxReportDetailsModal,
  DELETE_TAX_CLIENT: DeleteClientModal,
  EDIT_CLIENT_DETAILS: UpdateClientDetailsModal,
  BILLING_CHARGES: BillingChargesModal,
  UPDATE_CLIENT_TAX_METHOD: UpdateClientTaxMethodModal,
  UPDATE_CLIENT_ACCOUNT_TIMEZONE: UpdateClientAccountTimezoneModal,
  UPDATE_CLIENT_COST_BASIS_MODE: UpdateClientCostBasisModeModal,
  UPDATE_CLIENT_TAX_YEAR: UpdateClientTaxYearModal,
  TRIGGER_CLIENT_ACCOUNT_UPDATE: TriggerClientAccountUpdateModal,
  CLIENT_ACCESS_CHANGED: ClientAccessChangedModal,
  PREPARER_MODAL: PreparerModal,
  FEE_ASSIGNMENT: FeeAssignmentModal,
  // Tax Payer Specific
  TAX_CONSENT: TaxConsentModal,
  TAX_REVIEW: TaxReviewModal,
  IGNORE_TAX_TX: IgnoreTaxTxModal,
  UPDATE_TAX_TX_MATCH: UpdateTransferMatchModal,
  EDIT_COST_BASIS: EditCostBasisModal,
  REVERT_COST_BASIS: RevertCostBasisModal,
  ADD_TAX_PRO: AddTaxProModal,
  REMOVE_TAX_PRO: RemoveTaxProModal,
  CLIENT_REPORT_STATUS: ClientReportStatusModal,
  ADD_MANUAL_SOURCE: CreateManualSourceModal,
  RESEND_PREPARER_CODE_INVITE: ResendPreparerCodeInviteModal,
  MOBILE_TAX_FILTERS: MobileTaxTxFilterModal,
  MOBILE_ACCOUNTING_FILTERS: MobileTxFilterModal,
  CATEGORIZATION_ENGINE: TaxCategorizationModal,
  RESET_TAX_TX_CATEGORY: ResetTaxTxCategoryModal,
  SELECT_ORGANIZATION: SelectOrganizationModal,
  COST_ASSIGNMENT_DETAILS: CostAssignmentDetailsModal,
  UPDATE_PAYER_TAX_YEAR: UpdatePayerTaxYearModal,
  UPDATE_PAYER_TAX_METHOD: UpdatePayerTaxMethodModal,
  UPDATE_PAYER_COST_BASIS_MODE: UpdatePayerCostBasisModeModal,
  UPDATE_SPEC_ID_SETTINGS: UpdateSpecIdSettingsModal,
  UPDATE_SPEC_ID: UpdateSpecIdModal,
  SPEC_ID_WARNING: SpecIdWarningModal,
  // Tir Admin Specific
  GENERATE_REPORTING_FILE: GenerateReportingFileModal,
  SUBMIT_CORRECTION_FILE_TIR: SubmitCorrectionFileModal,
  ADD_TIR_ADMIN: AddTirAdminModal,
};

export default MODALS;
