// absolute
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import debounce from 'lodash/debounce';
import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';

// relative imports
import { checkInvalidInput } from '../../common/manual-entry/utilities/manualUtilities';
import Loader from '../custom-components/Loader';
import PopperVirtualized from '../custom-components/LedgiblePopper';
import { VirtualizedListboxComponent } from '../custom-components/AutoCompleteTextField';

const useVirtualizedStyles = makeStyles()(() => ({
  listbox: {
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    overflow: 'auto',
    maxHeight: 200,
  },
}));

const FormAutoCompleteField = ({
  freeSolo,
  fetching,
  fetchOptions,
  getOptionLabel,
  isOptionEqualToValue,
  handleChange,
  input: { onChange, onFocus, onBlur, value, ...inputProps },
  limit,
  match,
  meta: { error, touched, pristine, submitFailed, initial },
  noPlaceholderShrink,
  noOptionsText,
  optionList,
  parse,
  TextFieldProps,
  sort,
  sortBy,
  virtualize,
  'data-cy': cypressId,
  ...restAutoCompleteProps
}) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const { classes: virtualizedClasses } = useVirtualizedStyles();
  const containsError = !!(
    (touched && error && !pristine) ||
    (error && submitFailed)
  );

  // conditionalize application of virtualized Listbox based on prop
  const virtualizedProps = virtualize
    ? {
        classes: virtualizedClasses,
        disableListWrap: true,
        ListboxComponent: VirtualizedListboxComponent,
        PopperComponent: (popperProps) => {
          const { modifiers } = popperProps;
          return (
            <PopperVirtualized
              {...popperProps}
              modifiers={[
                ...(modifiers || []),
                { name: 'preventOverflow', enabled: false },
                { name: 'flip', enabled: false },
                { name: 'hide', enabled: false },
              ]}
            />
          );
        },
      }
    : {};

  const onInputChange = (e) => {
    onChange(parse(e.target.value));
  };

  const handleOnChange = (e, newVal, reason) => {
    if (reason !== 'blur') {
      onChange(newVal);
      handleChange(newVal, options);
    }
  };

  const handleIndirectSelection = () => {
    const selected = options.find((o) => isOptionEqualToValue(o, value));
    onChange(selected || value);
    handleChange(selected || value, options);
  };

  const onKeyDown = (e) => {
    if (e.key === 'Tab' && !checkInvalidInput(value)) {
      handleIndirectSelection();
    }
  };

  const onClose = (e, reason) => {
    if (reason === 'blur') {
      handleIndirectSelection();
    }
  };

  const getHelperText = () => {
    if (containsError) return error;
    return null;
  };

  const sortedOptions = useMemo(() => {
    if (sort) return sortBy ? sortBy([...optionList]) : [...optionList].sort();
    return optionList;
  }, [sort, sortBy, optionList]);

  const getAsyncOptions = useCallback(
    async (query) => {
      if (!query) return sortedOptions;

      if (fetchOptions) {
        return fetchOptions(query);
      }

      if (!Array.isArray(sortedOptions) || !sortedOptions.length) return [];

      return new Promise((resolve) => {
        setTimeout(() => {
          if (!Array.isArray(sortedOptions) || !sortedOptions.length)
            return resolve([]);
          if (!query) return resolve(sortedOptions);

          let filteredOptions = sortedOptions.filter((o) => {
            if (match === 'start')
              return o.toLowerCase().startsWith(query.toLowerCase());
            return o.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });

          if (typeof limit === 'number')
            filteredOptions = filteredOptions.slice(0, limit);
          return resolve(filteredOptions);
        }, 500);
      });
    },
    [fetchOptions, limit, match, sortedOptions],
  );

  const getOptionsDelayed = useMemo(
    () =>
      debounce((query, callback) => {
        setOptions([]);
        setLoading(true);
        getAsyncOptions(query).then(callback);
      }, 200),
    [getAsyncOptions],
  );

  useEffect(() => {
    getOptionsDelayed(value, (filteredOptions) => {
      setOptions(filteredOptions);
      setLoading(false);
    });

    return () => {
      getOptionsDelayed.cancel();
    };
  }, [value, getOptionsDelayed]);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      data-cy={cypressId}
      onClose={onClose}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      // disable filtering on client
      filterOptions={(o) => o}
      defaultValue={checkInvalidInput(initial) ? undefined : initial}
      loading={loading}
      value={value}
      onChange={handleOnChange}
      noOptionsText={noOptionsText}
      {...virtualizedProps}
      renderInput={(params) => (
        <TextField
          {...params}
          {...inputProps}
          {...TextFieldProps}
          error={containsError}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          helperText={getHelperText()}
          FormHelperTextProps={{
            style: {
              whiteSpace: 'pre-line',
            },
          }}
          value={value}
          InputProps={{
            ...(params.InputProps || {}),
            endAdornment: (
              <>
                {fetching && (
                  <InputAdornment position="end">
                    <Box mt={-1}>
                      <Loader size={20} thickness={6} />
                    </Box>
                  </InputAdornment>
                )}
                {params.InputProps.endAdornment}
              </>
            ),
            inputProps: {
              onFocus,
              ...params.inputProps,
              ...TextFieldProps.inputProps,
            },
          }}
          onBlur={onBlur}
          InputLabelProps={{
            shrink:
              (!!TextFieldProps.placeholder && !noPlaceholderShrink) ||
              undefined,
            ...TextFieldProps.InputLabelProps,
          }}
        />
      )}
      {...restAutoCompleteProps}
    />
  );
};

export default FormAutoCompleteField;

FormAutoCompleteField.propTypes = {
  freeSolo: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  handleChange: PropTypes.func,
  input: PropTypes.shape(),
  limit: PropTypes.number,
  match: PropTypes.oneOf(['start', 'any']),
  meta: PropTypes.shape(),
  noOptionsText: PropTypes.string,
  noPlaceholderShrink: PropTypes.bool,
  optionList: PropTypes.arrayOf(PropTypes.string),
  parse: PropTypes.func,
  sort: PropTypes.bool,
  sortBy: PropTypes.func,
  TextFieldProps: PropTypes.shape(),
  virtualize: PropTypes.bool,
  'data-cy': PropTypes.string,
};

FormAutoCompleteField.defaultProps = {
  freeSolo: true,
  fetching: false,
  fetchOptions: null,
  getOptionLabel: (label) => label,
  isOptionEqualToValue: (option, value) => option === value,
  handleChange: () => null,
  input: {},
  limit: 500,
  match: 'start',
  meta: {},
  noOptionsText: 'No options',
  noPlaceholderShrink: false,
  optionList: [],
  parse: (v) => v,
  sort: false,
  sortBy: (optionList) => optionList.sort((a, b) => (a > b ? 1 : -1)),
  TextFieldProps: {},
  virtualize: false,
  'data-cy': undefined,
};
