// absolute imports
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Field } from 'react-final-form';

// relative imports
import FormReviewField from '../../../shared/final-form-field-wrappers/FormReviewField';
import { getTimezoneDisplayPartialString } from '../../../shared/utilities/dateUtilities';
import { checkInvalidInput, handleManualDateDisplay, handleManualTimeDisplay } from '../utilities/manualUtilities';
import { contextTitleRef } from '../manualRef';
import { properCase } from '../../../shared/utilities/stringUtilities';

// STYLES FOR FORM
const useStyles = makeStyles()((theme, { maxWidth }) => ({
  reviewContainer: {
    overflowX: 'scroll',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: maxWidth || '85vw',
  },
  reviewItem: {
    marginBottom: theme.spacing(0.5),
  },
}));

/**
 * Used when you would like to display fields that the user has entered
 * on a previous step without taking too much space on the current step
 * Should only be used within a React Final Form <Form /> component
 */
const EntryProgress = ({
  formFields,
  includeSymbol,
  isReview,
  maxWidth,
  showDivider,
  taxCategoryTitleMap,
  values,
  isMobile,
}) => {
  const { classes } = useStyles({ maxWidth });
  const { side, sourceType, useFiat } = values;

  // only non-editable fields are to be displayed
  const defaultFormFields = [
    {
      id: 0,
      value: 'date',
      display: 'Date',
      format: handleManualDateDisplay,
    },
    {
      id: 1,
      value: 'time',
      display: 'Time',
      format: handleManualTimeDisplay,
    },
    {
      id: 2,
      value: 'timezone',
      display: 'Timezone',
      format: (val) => getTimezoneDisplayPartialString(val, isMobile),
    },
    {
      id: 3,
      value: 'context',
      display: 'Context',
      format: (value) => contextTitleRef[value],
      hidden: useFiat,
    },
    {
      id: 4,
      value: 'taxCategory',
      display: 'Categorization',
      format: (value) => taxCategoryTitleMap[value],
      hidden: checkInvalidInput(values?.taxCategory) || useFiat,
    },
    {
      id: 5,
      value: 'side',
      display: 'Side',
      format: (value) => properCase(value),
      hidden: sourceType === 'exchange' || !sourceType || !side,
    },
  ];

  if (isReview || includeSymbol)
    defaultFormFields.push({
      id: 20,
      value: 'symbol',
      display: 'Currency Symbol',
    });

  const fields = formFields || defaultFormFields;

  return (
    <Paper className={classes.reviewContainer}>
      {fields.map((field, index) =>
        field.value && !field.hidden ? (
          <div
            key={field.id}
            className={
              index === fields.length - 1 ? undefined : classes.reviewItem
            }
          >
            <Field
              name={field.value}
              displayName={field.display}
              nullValue={field.nullValue}
              component={FormReviewField}
              format={field.format}
              hideDivider={!showDivider || index === fields.length - 1}
            />
          </div>
        ) : null,
      )}
    </Paper>
  );
};

export default EntryProgress;

EntryProgress.propTypes = {
  values: PropTypes.shape(),
  formFields: PropTypes.arrayOf(PropTypes.shape()),
  includeSymbol: PropTypes.bool,
  isReview: PropTypes.bool,
  isMobile: PropTypes.bool,
  showDivider: PropTypes.bool,
  taxCategoryTitleMap: PropTypes.shape(),
  maxWidth: PropTypes.number,
};

EntryProgress.defaultProps = {
  values: {},
  taxCategoryTitleMap: {},
  formFields: null,
  isReview: false,
  isMobile: false,
  includeSymbol: false,
  showDivider: false,
  maxWidth: undefined,
};
